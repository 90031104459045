import React, {Component} from 'react';
import {Button, Col, Nav, Navbar, NavItem, Row} from 'react-bootstrap';
import FontAwesome from 'react-fontawesome';
import {Link} from 'react-router-dom';
import {Breadcrumbs} from '../Breadcrumbs/Breadcrumbs';
import {Spinner} from '../Spinner/Spinner';
import './StateRaces.css';
import {getRaces} from './StateRaces.service';
import {FacebookShareButton} from "react-share";

export class StateRaces extends Component {
    constructor(props, context) {
        super(props, context);
        this.state = {races: undefined};

    }

    componentDidUpdate(prevProps, prevState, prev) {
        if(prevProps.match.params.year !== undefined && prevProps.match.params.year !== this.getYear()) {
            this.doRaces();
        }
    }

    componentDidMount() {
        this.doRaces();
    }

    doRaces() {
        getRaces(this.props.match.params.state.toUpperCase(), this.getYear()).then(races => this.setState({races}));
    }

    getYear() {
        return this.props.match.params.year ? this.props.match.params.year : 2019
    }

    render() {
        let state = this.props.match.params.state;
        return (
            <span>
        <Breadcrumbs state={state} year={this.getYear()}/>
      <Row>
        <Col xs={12} md={12} lg={10} lgOffset={1}>
        {!this.state.races ? <Spinner/>
            : this.state.races.length === 0 &&
            <h2 className="text-center">It appears this state doesn't have any races :(</h2>}
            {this.state.races && this.state.races.map(race => {

                let url = `/states/${state}/races/${race.year}/${race.id}/${race.complete ? 'results' : 'prediction'}`;
                if(race.bikeReg.is && !race.complete) {
                    url = `/states/${state}/races/${race.year}/${race.bikeReg.id}/entries`;
                }

                return (<Navbar>
                    <Navbar.Header>
                        <Navbar.Brand>
              <span className="hidden-xs">
               {race.id
                   ? <Link to={url}>
                       {race.complete && <span><FontAwesome name="check-square"/>&nbsp;&nbsp;</span>}
                       {`${race.name}`}
                       <small>
                           <small>
                               <small> - [ {race.city} ] - {race.date}</small>
                           </small>
                       </small>
                   </Link>
                   : <span>{`${race.name}`}
                       <small><small><small> - [ {race.city} ] - {race.date}</small></small></small></span>
               }
              </span>
                            <span className="visible-xs">

               {race.id
                   ? <Link to={url}>
                       {race.complete && <span><FontAwesome name="check-square"/>&nbsp;&nbsp;</span>}
                       {`${race.name}`}
                   </Link>
                   : <span>{`${race.name}`}</span>
               }
              </span>
                        </Navbar.Brand>
                        <Navbar.Toggle/>
                    </Navbar.Header>
                    <Navbar.Collapse>
                        {race.id &&
                        <Nav pullRight>
                            <NavItem className="visible-xs">[ {race.city} ] - {race.date}</NavItem>
                            <NavItem href={url}>{race.complete ? 'Results' : 'Registrations'}</NavItem>
                            <NavItem><FacebookShareButton url={`https://usacx.co/${url}`} quote={race.complete ? `Check out who won at ${race.name}` : `Check out who is registered for ${race.name}`}>Share</FacebookShareButton></NavItem>
                            <NavItem target="_blank"
                                     href={`https://www.usacycling.org/events/getflyer.php?permit=${race.year}-${race.id}`}>Flyer</NavItem>
                            {!race.complete &&
                                race.bikeReg.is
                              ? <NavItem target="_blank" href={race.externalRegistration}>Register</NavItem>
                              : <NavItem target="_blank" href={`https://www.usacycling.org/register/${race.year}-${race.id}`}>Register</NavItem>
                            }
                        </Nav>
                        }
                        {!race.id &&
                        <Nav pullRight>
                            <NavItem className="visible-xs">[ {race.city} ] - {race.date}</NavItem>
                            <NavItem className="no-permit">This race currently has no permit.</NavItem>
                        </Nav>
                        }
                    </Navbar.Collapse>
                </Navbar>);
            })}
      </Col></Row>
      </span>
        );
    }
}