import React from 'react';
import './RacerIcon.css';

const images = {
  'JOE PETERSENDES MOINES, IA': 'https://bargeek.files.wordpress.com/2009/03/pbr_can.jpg',
  'CHAD DONAHUEDES MOINES, IA': 'https://pbs.twimg.com/profile_images/631910654576713728/cX9NRswP.png',
  'STEPHEN HYDEEASTHAMPTON, MA': 'https://upload.wikimedia.org/wikipedia/en/thumb/a/a4/Flag_of_the_United_States.svg/1280px-Flag_of_the_United_States.svg.png',
  'KATHERINE COMPTONCOLORADO SPRINGS, CO': 'https://upload.wikimedia.org/wikipedia/en/thumb/a/a4/Flag_of_the_United_States.svg/1280px-Flag_of_the_United_States.svg.png',
  'JASON SCHOLBROCKDES MOINES, IA': 'http://www.taproduce.com/wp-content/uploads/2017/02/GreenLeaf_DSC3236-e1486411403980.png',
  'KYLE SEDOREDES MOINES, IA': 'https://apps.voxmedia.com/graphics/theverge-android-emoji/images/emoji_u1f918-0aab50d1.png',
  'TYLER REYNOLDSPELLA, IA': 'https://cl.ly/1e9e415c58c4/tyler.png',
  'STEVE REYNOLDSPELLA, IA': 'https://cl.ly/7bf653b5a142/steve.png',
  'TAYLOR WEBBW. DES MOINES, IA': 'https://assets.digitalocean.com/articles/Unicorn_Ruby/img1.png',
  'MARK CHILDDES MOINES, IA': 'https://www.yoloboard.com/media/catalog/product/cache/1/image/1800x/040ec09b1e35df139433887a97daa66f/y/o/yolo_board_straw_hat_adult_size.png',
  'BRIAN WESTWINTERSET, IA': 'https://cl.ly/5ef0b73498c7/bwest.png',
  'BRIAN WESTWINTERSET': 'https://cl.ly/5ef0b73498c7/bwest.png',
    'JAMES COCHRANIOWA CITY, IA': 'https://cl.ly/be8864bb28d4/Image%2525202018-10-22%252520at%2525207.32.26%252520PM.png',
    'JASON ANDERSONDES MOINES, IA': 'https://cl.ly/c1c6d002545d/Image%2525202018-10-22%252520at%2525207.38.21%252520PM.png',
    'SCOTT OLSENMADRID, IA': 'https://cl.ly/d8a22b9c2287/Image%2525202018-10-22%252520at%2525207.39.56%252520PM.png',
    'ROBERT VERSTEEGHANKENY, IA': 'https://cl.ly/d8a22b9c2287/Image%2525202018-10-22%252520at%2525207.39.56%252520PM.png',
    'JANAN FELTSDES MOINES, IA': 'https://cl.ly/b992a31757a9/Image%2525202018-10-22%252520at%2525207.46.37%252520PM.png',
    'JANAN HICKSDES MOINES, IA': 'https://cl.ly/b992a31757a9/Image%2525202018-10-22%252520at%2525207.46.37%252520PM.png',
};

export const RacerIcon = ({name, location}) => (
  <span>
    {images[name.trim().toUpperCase()+location.trim().toUpperCase()] && <img src={images[name.trim().toUpperCase()+location.trim().toUpperCase()]} className="racer-icon" alt={name} />}
  </span>
);
