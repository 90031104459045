import * as cheerio from 'cheerio';
import rp from 'request-promise';
import moment from 'moment';

const getExternalRegistrationLink = link => {
  const href = cheerio.load(link)('a').attr('href');
  return href.includes('http') ? href : '';
};

const getTheGrand = () => ({
  bikeReg: {id: "the-grand", is: true},
  city: "Des Moines, IA",
  complete: !moment().isBefore('2019-10-20'),
  date: "10/19/2019 - 10/20/2019",
  externalRegistration: "https://bikereg.com/the-grand",
  hasRegistration: true,
  id: "2299",
  lastDate: 1571630400000,
  name: "The Grand p/b Lean Techniques",
  momentDate: moment('2019-10-19'),
  year: 2019,
});

export const getRaces = (state, year) =>
  new Promise(resolve => {
    rp({
      uri: `https://api.usacx.co/races/cyclocross?state=${state}&fyear=${year}`,
      headers: {
        accept: 'application/json',
        'content-type': 'application/json',
      },
    }).then(response => {
      const data = cheerio.load(response);
      const raceRows = data('table').find('.homearticlebody').find('b:nth-child(1)');
      const racesData = raceRows.map((i, r) => {
        if(!data(r).text().includes('Event is not yet permitted') && !data(r).text().includes('View Results')) {
          return {
            r,
            complete: data(raceRows[i + 1]).text().includes('View Results')
          };
        }
      });

      const races = [];
      racesData.map((i, {r, complete}) => {
        const name = data(r).text();
        const raceData = data(r).parent().html().split('<br>');
        const city = raceData.filter(d => d.includes(state))[ 0 ].trim();
        const date = raceData.filter(d => d.includes(`/${year}`) && !d.includes('Permit'))[ 0 ].trim();
        const momentDate = moment(date.split(' - ')[0]);
        const registrationLinkRaw = raceData.filter(d => d.includes('Online Registration'));
        const hasRegistration = registrationLinkRaw && registrationLinkRaw.length > 0;
        let externalRegistration = hasRegistration ? getExternalRegistrationLink(registrationLinkRaw[0]) : '';
        let bikeRegId = externalRegistration.substring(externalRegistration.lastIndexOf("/") + 1);
        let isBikeReg = externalRegistration.includes('bikereg');

        const permitData = raceData[ 1 ].replace('Permit Number: ', '').replace('&#xA0;&#xA0;', '').trim();
        const lastDate = new Date(date.split(' - ')[date.split(' - ').length - 1]).setHours(23);
        complete = complete ? complete : lastDate <= new Date();
        let id;

        if (permitData.includes(year)) {
          id = permitData.split('-')[ 1 ];
        }

        // opps i messed up the grand and USAC sucks at technology
        if(id === '2299') {
          externalRegistration = 'https://bikereg.com/the-grand';
          bikeRegId = 'the-grand';
          isBikeReg = true;
        }

        races.push({ year, id, name, date, city, complete, externalRegistration, hasRegistration, lastDate, momentDate, bikeReg: { id: bikeRegId, is: isBikeReg}});
      });

      console.log(year, state);
      if(year === 2019 && ['mo', 'wi', 'ne', 'sd', 'il', 'ks', 'mn'].includes(state.toLowerCase())) {
        races.push(getTheGrand());
      }

      resolve(races.sort((a, b) => a.momentDate.diff(b.momentDate)));
    });
  });