export const byAbbreviation = abbreviation =>
  states.filter(s => s.abbreviation.toLowerCase() === abbreviation.toLowerCase())[0];

export const byName = name =>
  states.filter(s => s.name.toLowerCase() === name.toLowerCase())[0];

export const states = [ 
  { name: 'ALABAMA', abbreviation: 'AL', latitude: 32.806671, longitude: -86.791130},
  { name: 'ALASKA', abbreviation: 'AK', latitude: 61.370716, longitude: -152.404419},
  { name: 'ARIZONA', abbreviation: 'AZ', latitude: 33.729759, longitude: -111.431221},
  { name: 'ARKANSAS', abbreviation: 'AR', latitude: 34.969704, longitude: -92.373123},
  { name: 'CALIFORNIA', abbreviation: 'CA', latitude: 36.116203, longitude: -119.681564},
  { name: 'COLORADO', abbreviation: 'CO', latitude: 39.059811, longitude: -105.311104},
  { name: 'CONNECTICUT', abbreviation: 'CT', latitude: 41.597782, longitude: -72.755371},
  { name: 'DELAWARE', abbreviation: 'DE', latitude: 39.318523, longitude: -75.507141},
  { name: 'DISTRICT OF COLUMBIA', abbreviation: 'DC', latitude: 38.897438, longitude: -77.026817},
  { name: 'FLORIDA', abbreviation: 'FL', latitude: 27.766279, longitude: -81.686783},
  { name: 'GEORGIA', abbreviation: 'GA', latitude: 33.040619, longitude: -83.643074},
  { name: 'HAWAII', abbreviation: 'HI', latitude: 21.094318, longitude: -157.498337},
  { name: 'IDAHO', abbreviation: 'ID', latitude: 44.240459, longitude: -114.478828},
  { name: 'ILLINOIS', abbreviation: 'IL', latitude: 40.349457, longitude: -88.986137},
  { name: 'INDIANA', abbreviation: 'IN', latitude: 39.849426, longitude: -86.258278},
  { name: 'IOWA', abbreviation: 'IA', latitude: 42.011539, longitude: -93.210526},
  { name: 'KANSAS', abbreviation: 'KS', latitude: 38.526600, longitude: -96.726486},
  { name: 'KENTUCKY', abbreviation: 'KY', latitude: 37.668140, longitude: -84.670067},
  { name: 'LOUISIANA', abbreviation: 'LA', latitude: 31.169546, longitude: -91.867805},
  { name: 'MAINE', abbreviation: 'ME', latitude: 44.693947, longitude: -69.381927},
  { name: 'MARYLAND', abbreviation: 'MD', latitude: 39.063946, longitude: -76.802101},
  { name: 'MASSACHUSETTS', abbreviation: 'MA', latitude: 42.230171, longitude: -71.530106},
  { name: 'MICHIGAN', abbreviation: 'MI', latitude: 43.326618, longitude: -84.536095},
  { name: 'MINNESOTA', abbreviation: 'MN', latitude: 45.694454, longitude: -93.900192},
  { name: 'MISSISSIPPI', abbreviation: 'MS', latitude: 32.741646, longitude: -89.678696},
  { name: 'MISSOURI', abbreviation: 'MO', latitude: 38.456085, longitude: -92.288368},
  { name: 'MONTANA', abbreviation: 'MT', latitude: 46.921925, longitude: -110.454353},
  { name: 'NEBRASKA', abbreviation: 'NE', latitude: 41.125370, longitude: -98.268082},
  { name: 'NEVADA', abbreviation: 'NV', latitude: 38.313515, longitude: -117.055374},
  { name: 'NEW HAMPSHIRE', abbreviation: 'NH', latitude: 43.452492, longitude: -71.563896},
  { name: 'NEW JERSEY', abbreviation: 'NJ', latitude: 40.298904, longitude: -74.521011},
  { name: 'NEW MEXICO', abbreviation: 'NM', latitude: 34.840515, longitude: -106.248482},
  { name: 'NEW YORK', abbreviation: 'NY', latitude: 42.165726, longitude: -74.948051},
  { name: 'NORTH CAROLINA', abbreviation: 'NC', latitude: 35.630066, longitude: -79.806419},
  { name: 'NORTH DAKOTA', abbreviation: 'ND', latitude: 47.528912, longitude: -99.784012},
  { name: 'OHIO', abbreviation: 'OH', latitude: 40.388783, longitude: -82.764915},
  { name: 'OKLAHOMA', abbreviation: 'OK', latitude: 35.565342, longitude: -96.928917},
  { name: 'OREGON', abbreviation: 'OR', latitude: 44.572021, longitude: -122.070938},
  { name: 'PENNSYLVANIA', abbreviation: 'PA', latitude: 40.590752, longitude: -77.209755},
  { name: 'RHODE ISLAND', abbreviation: 'RI', latitude: 41.680893, longitude: -71.511780},
  { name: 'SOUTH CAROLINA', abbreviation: 'SC', latitude: 33.856892, longitude: -80.945007},
  { name: 'SOUTH DAKOTA', abbreviation: 'SD', latitude: 44.299782, longitude: -99.438828},
  { name: 'TENNESSEE', abbreviation: 'TN', latitude: 35.747845, longitude: -86.692345},
  { name: 'TEXAS', abbreviation: 'TX', latitude: 31.054487, longitude: -97.563461},
  { name: 'UTAH', abbreviation: 'UT', latitude: 40.150032, longitude: -111.862434},
  { name: 'VERMONT', abbreviation: 'VT', latitude: 44.045876, longitude: -72.710686},
  { name: 'VIRGINIA', abbreviation: 'VA', latitude: 37.769337, longitude: -78.169968},
  { name: 'WASHINGTON', abbreviation: 'WA', latitude: 47.400902, longitude: -121.490494},
  { name: 'WEST VIRGINIA', abbreviation: 'WV', latitude: 38.491226, longitude: -80.954453},
  { name: 'WISCONSIN', abbreviation: 'WI', latitude: 44.268543, longitude: -89.616508},
  { name: 'WYOMING', abbreviation: 'WY', latitude: 42.755966, longitude: -107.302490}, ];