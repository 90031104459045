import React from 'react';
import { Panel, Table } from 'react-bootstrap';
import { RacerIcon } from '../../RacerIcon/RacerIcon';
import { Spinner } from '../../Spinner/Spinner';
import './PredictionRaces.css';

const racersInHeader = (race, racers) => {
  if (race.entries !== undefined) return ` [ ${race.entries} Racer(s) ]`;
  return racers && racers.length > 0 ? ` [ ${racers.length} Racer(s) ]` : '';
};

export const PredictionRaces = ({ race, racers, i, ...rest}) => (
  race.heading ?
    <h4><br />{race.name}</h4>
    :
    <Panel header={`${race.name}${racersInHeader(race, racers)}`} {...rest} >
      {!racers && <Spinner/>}
      {racers && racers.length === 0 && <span>There are currently no rad individuals registered.</span>}
      {racers && racers.length > 0 && racers[0].bikereg === true && <Table striped bordered condensed hover responsive>
        <thead>
        <tr>
          <th>Name</th>
          <th>Team</th>
          <th>Location</th>
        </tr>
        </thead>
        <tbody>
        {racers.map((racer) =>
          <tr>
            <td>{racer.name} <RacerIcon name={racer.name} location={racer.location}/></td>
            <td>{racer.team}</td>
            <td>{racer.location}</td>
          </tr>)}
        </tbody>
      </Table>}

      {racers && racers.length > 0 && racers[0].bikereg !== true && <Table striped bordered condensed hover responsive>
        <thead>
        <tr>
          <th>(#) Points</th>
          <th>Name</th>
          <th>Team</th>
          <th>Location</th>
        </tr>
        </thead>
        <tbody>
        {racers.map((racer, num) =>
          <tr>
            <td>({num + 1}) {racer.points}</td>
            <td>{racer.name} <RacerIcon name={racer.name} location={racer.location}/></td>
            <td>{racer.team}</td>
            <td>{racer.location}</td>
          </tr>)}
        </tbody>
      </Table>}
    </Panel>
);



