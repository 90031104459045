import * as cheerio from 'cheerio';
import rp from 'request-promise';


export const getBikeRegRace = (name) =>
  new Promise(resolve => {
    rp({
      uri: `https://api.usacx.co/bikereg/${name}`,
      headers: {
        accept: 'application/json',
        'content-type': 'application/json',
      },
    }).then(response => {
      const data = cheerio.load(response);

      const urlHref = data('li.whos-registered').find('a').prop('href');
      const id = urlHref.substring(urlHref.lastIndexOf("/") + 1);

      resolve(id);
    });
  });

export const getRacers = (id) =>
  new Promise(resolve => {
    rp({
      uri: `https://api.usacx.co/predictions?race=${id}`,
      headers: {
        accept: 'application/json',
        'content-type': 'application/json',
      },
    }).then(response => {
      const racers = [];
      const data = cheerio.load(response);
      const racerRows = data('table').find('tr');
      racerRows.each((i, r) => {
        const name = data(r).find('td:nth-child(2)').text();
        const team = data(r).find('td:nth-child(3)').text();
        racers.push({
          rank: data(r).find('td:nth-child(1)').text(),
          name,
          team,
          location: data(r).find('td:nth-child(4)').text(),
          points: data(r).find('td:nth-child(5)').text(),
        });
      });

      resolve(racers.filter(r => r.name !== ""));
    });
  });

export const getBikeRegRacers = (eventId, raceId) =>
  new Promise(resolve => {
    rp({
      uri: `https://api.usacx.co/bikereg/racers?eventId=${eventId}&raceId=${raceId}&shrug=${Math.floor(Math.random() * Math.floor(1000))}`,
      headers: {
        accept: 'application/json',
        'content-type': 'application/json',
      },
    }).then(response => {
      const racers = [];
      const data = cheerio.load(response);
      const racerRows = data('tbody').find('tr');
      racerRows.each((i, r) => {
        racers.push({
          rank: 0,
          name: `${data(r).find('td.firstname').text().trim()} ${data(r).find('td.lastname').text().trim()}`,
          team: data(r).find('td.team').text().trim(),
          location: data(r).find('td.city').text().trim(),
          points: '',
          bikereg: true,
        });
      });

      resolve(racers.filter(r => r.name !== ""));
    });
  });

export const getBikeRegRaces = (id) =>
  new Promise(resolve => {
    rp({
      uri: `https://api.usacx.co/bikereg/races/${id}`,
      headers: {
        accept: 'application/json',
        'content-type': 'application/json',
      },
      noCors: true,
    }).then(response => {
        try {
          const races = [];
          const data = cheerio.load(response);

          const raceTables = data('table.categoryName, div.groupheader');
          const name = data('div.event-header').find('h1').text().trim();

          let currentCategory = '';
          raceTables.each((r, i) => {
            const theData = data(i);

            if (i.name === 'div')
            {
              races.push({
                heading: true,
                name: theData.find('h3').text().trim(),
              });
              return;
            }

            races.push({
              id: theData.prop('racerecid'),
              name: `${currentCategory}${theData.find('div:nth-child(2)').text().trim()}`,
              entries: theData.find('div:nth-child(1)').text().trim().replace(' entry +', '').replace(' entries +', ''),
            })
          });

          resolve({ races, name });
        } catch (e) {
          resolve({ races: undefined, name: '¯\\_(ツ)_/¯' });
        }
      }
    );
  });

export const getRaces = (year, id) =>
  new Promise(resolve => {
    rp({
      uri: `https://api.usacx.co/registration-races?eventid=${id}&year=${year}`,
      headers: {
        accept: 'application/json',
        'content-type': 'application/json',
      },
    }).then(response => {
        try {
          const races = [];
          const data = cheerio.load(response);
          const raceTables = data('table').find('td').find('table');
          const name = data('table').find('td:nth-child(1)')
            .find('b').text().replace('Current registrations for ', '').replace('Riders', '').split(':')[ 0 ];
          raceTables.each((i, r) => {
            const date = data(r).find('td:nth-child(1)').text();
            const gender = data(r).find('td:nth-child(3)').text();
            const race = data(r).find('td:nth-child(4)').text();
            const id = data(r).parent().parent().next().attr('id').substring(3);

            races.push({
              id,
              name: `${date} - ${gender} - ${race}`,
            });
          });

          resolve({ races, name });
        } catch (e) {
          resolve({ races: undefined, name: '¯\\_(ツ)_/¯' });
        }
      }
    );
  });
